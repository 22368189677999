/* Se import React, useState, useEffect y useContext */
import React, { useState, useEffect, useContext } from 'react';
/* Se importa uri de login */
import { login, encriptarDesencriptar } from '../../utils/connections/uris';
/* Se importa la libreria react-modal */
import Modal from 'react-modal';
/* Se importa el scss de Login */
import './Login.scss';
/* Se importa el componente ModalCorrecto, este componente es para cuando las credenciales son correctas*/
import ModalCorrecto from '../ModalCorrecto';
/* Se importa el componente ModalGeneral, este componente es para cuando las credenciales som incorrectas y validaciones */
import ModalGeneral from '../ModalGeneral';
/* Se importa el logo de Giza */
import logo from '../../img/Giza_blanco_1.png';
/* Se importa el componente Loading, este componente es cuando la pagina carga, y se vea de fondo */
import Loading from '../Loading';
/* Se importa el DataContext, aqui en Login se llena con el id y el tipo de usuario*/
import { DataContext } from '../dataContext/dataContext';
/* Se importa la libreria de fontAwesomeIcon, el cual funciona para ponerle el icono al input contraseña para mostrar / ocultar contraseña*/
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/* Se Importa el icono del ojo para mostrar contraseña */
import { faEye } from "@fortawesome/free-solid-svg-icons";
/* Se importa el icono del ojo negado, para ocultar contraseña */
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
/* Se crea una variable con el objeto ojo para mostrar el icono */
const eye = <FontAwesomeIcon icon={faEye} />;
/* Se crea una variable con el objeto ojo negado para mostrar el icono */
const eyeOculto = <FontAwesomeIcon icon={faEyeSlash} />;
/* Se configura que el modal(es) se muestren en root, el cual es el contenedor principal de React*/
Modal.setAppElement('#root')
/* Se crea la funcion del componente login*/
const Login = ({ validarLogin }) => {
    /* Se acceden a los datos del context, para modificarlo */
    const { setTipo, setId, setPermanencia } = useContext(DataContext)
    /* Se crea un useState de los datos que se llenan desde el Form Login*/
    const [datos, setDatos] = useState({ usuario: "", pass: "" });
    /* Se crea un useState de los datos que se envian al API*/
    const [envio, setEnvio] = useState({});
    /* Se crea un useState del mensaje advertencia que llevara el Modal*/
    const [advertencia, setAdvertencia] = useState("");
    /* Se crea un useState del titulo del Modal*/
    const [titulo, setTitulo] = useState("");
    /* Se crea un useState de la descripcion del error o advertencia del Modal*/
    const [descripcion, setDescripcion] = useState("");
    /* Se crea un useState para mostrar el modal de Errores y Advertencias (ModalGeneral)*/
    const [modalError, setModalError] = useState(false);
    /* Se crea un useState para mostrar el modal de credenciales correctas (ModalCorrecto)*/
    const [modalCorrecto, setModalCorrecto] = useState(false);
    /* Estado para nickEncriptado */
    const [nickEncriptado, setNickEncriptado] = useState("");
    /* Estado para passEncriptado */
    const [passEncriptado, setPassEncriptado] = useState("");
    /* Se crea un useState para mostrar el Gif de Loading*/
    const [gif, setGif] = useState(false);
    /* Se crea un useState para mostrar el icono de mostrar / ocultar contraseña*/
    const [type, setType] = useState("Oculto");
    /* Se crea una variable de la contraseña, esta se crea para acceder al input de contraseña para cambiar el icono de mostrar / ocultar*/
    var password = document.getElementById("txtPass");
    /* Se crea una funcion para validar los input */
    const validarDatos = (event) => {
        /* Se accede al input Usuario */
        let user = event.target.elements.usuario;
        /* Se accede al input Pass */
        let pass = event.target.elements.pass;
        /* Se previene eventos por default, esto previene recarga de pagina */
        event.preventDefault();
        /* Valida si el campo usuario esta vacio */
        if (user.value === "") {
            setAdvertencia("Warning");
            /* Se mostrara modal de Errores y Advertencias*/
            setModalError(true);
            /* Se llena el titulo del Modal */
            setTitulo("Campo Usuario Vacío")
            /* Se llena la descripcion del Modal */
            setDescripcion("Campo usuario vacío")
            /* Valida si el campo contraseña esta vacio */
        } else if (pass.value === "") {
            /* Se mostrara el icono Warning */
            setAdvertencia("Warning");
            /* Se mostrara modal de Errores y Advertencias*/
            setModalError(true);
            /* Se llena el titulo del Modal */
            setTitulo("Campo Contraseña Vacío")
            /* Se llena la descripcion del Modal */
            setDescripcion("Campo contraseña vacío")
        } else {
            /* Si ya los input usuario y contraseña no estan vacios, se llena el state Envio*/
            setEnvio(datos)
        }
    }
    /* Funcion para el click del boton de mostrar / ocultar contraseña */
    const click = () => { 
        if (password !== null) {
            /* Valida si se da clic en mostrar contraseña */
            if (type === "Oculto") {
                /* Cambia a input tipo Text*/
                password.type = "text";
                /* Cambia el state type a Mostrar */
                setType("Mostrar");
                /* Si esta el state en Mostrar, debe cambiar el campo contraseña a ocultar contraseña*/
            } else if (type === "Mostrar") {
                /* Cambia a input tipo password*/
                password.type = "password";
                /* Cambia el state type a oculto */
                setType("Oculto");
            }
        }
    }

    function encriptar(dato, callback) {
        let statusText = "";
        let status = "";
        fetch(encriptarDesencriptar, {
            //Consumo de api
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                opcion: "1",
                dato: dato,
            },
        })
            .then(function (response) {
                //Se obtiene la respuesta de la api
                status = response.status;
                statusText = response.statusText;
                return response.json();
            })
            .then(function (data) {
                //Transformación de la respuesta a formato JSON
                if (data.code === 0) {
                    //Se verifica que el la respuesta sea correcta
                    return callback(data.data); //Se devuelve el contenido encriptado
                } else {
                    //Estatus de la api fallido
                    // setCargando(false);
                    // setMensajeError(data.message);
                    // setMostrarModal(true);
                    return callback(null); //Se devuelve nulo para que no siga el proceso
                }
            })
            .catch(function (error) {
                //Surgio algún error que no permitio obtener el resultado de la api
                //setCargando(false);
                if (statusText) {
                    //setMensajeError(status + " " + statusText);
                } else {
                    //setMensajeError(error);
                }
                //setMostrarModal(true);
                return callback(null); //Se devuelve nulo para que no siga el proceso
            });
    }
    /* Se crea un useEffect, para consumir las dos API (Usuarios Admin y Usuarios Cliente) */
    useEffect(() => { 
        /* Se crea una funcion para el control de mensajes en los Modales */
        const validar = async (nickEncriptado, passEncriptado) => {

            /* Se usa Fetch para consumir API, en este caso API Usuarios Admin */
            const response = await fetch(login, {
                //Consumo de api
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    nick: nickEncriptado,
                    pass: passEncriptado,
                },
            });

            //Transformación de la respuesta a formato JSON
            const responseJson = await response.json();
            /* Se muestra el gif, mientras se hace la peticion*/
            setGif(true);
            /* Se limpia el mensaje de advertencia, por si quedo algun llamado y causa errores */
            setAdvertencia("");
            /* Se oculta el modal general */
            setModalError(false);
            /* Se oculta el modal Correcto */
            setModalCorrecto(false);
            /* Se limpia el titulo del modal, por si quedo algun llamado y causa errores */
            setTitulo("")
            /* Se limpia la descripcion del modal, por si quedo algun llamado y causa errores */
            setDescripcion("")
            /* Se asigna 0 a la sessionStorage */
            sessionStorage.setItem('valido', 0);
            /* Se valida que si es el mensaje que retorna la peticion es diferente a No existe el usuario
            Se muestre el modal correcto y se llene el titulo, descripcion e imagen del modal */
            if (responseJson.code === 0) {
                /* Detiene el gif de loading */
                setGif(false);
                /* Se cambia el state de mostrarCorrecto, esto provoca que se muestre el modal Correcto */
                setModalCorrecto(true);
                /* Se modifica el state Titulo y da la bienvenida al Usuario logueado */
                try {
                    setTitulo("Bienvenido " + responseJson.data[0].nombre);
                    setId(responseJson.data[0].id);
                    setTipo(responseJson.data[0].permiso);
                }
                catch {
                    setTitulo("Bienvenido " + responseJson.data.nombre);
                    setId(responseJson.data.id);
                    /* El tipo de usuario accedido sera 1 */
                    setTipo(responseJson.data.permiso);
                }
                /* Se modifica el id */

                /* Se cambia permanencia a True, esto actualmente no provoca cambios en la pagina, pero se llena para futuro */
                setPermanencia(true);
                /* Se cambia la sessionStorage a 1 */
                sessionStorage.setItem('valido', 1);
                /* Se Limpia state Envio */
                setEnvio({});
            }
            else {
                /* Se detiene el Gif de carga */
                setGif(false);
                /* Se modifica el state de la imagen del Modal */
                setAdvertencia("Error");
                /* Se modifica el state del titulo del Modal */
                setTitulo("Acceso incorrecto")
                /* Se modifica el state de la descripcion del Modal */
                setDescripcion(responseJson.message)
                /* Se modifica el state de ModalError, esto muestra el modal de Advertencias y Errores */
                setModalError(true);
                /* Se limpia el state Envio */
                setEnvio({});
                /* Se asigna 0 a sessionStorage */
                sessionStorage.setItem('valido', 0);
            }

        };
        /* Valida si existen datos en state Envio y si existen datos, se hacen las peticiones */
        if (Object.keys(envio).length !== 0) {
            encriptar(envio.usuario, function (nickEncriptado) {
                //Consumo de la api encriptar nick
                if (nickEncriptado) {
                    //Se verifica si el estatus del consumo es exitoso
                    encriptar(envio.pass, function (passEncriptado) {
                        //Consumo de la api encriptar pass
                        if (passEncriptado) {
                            //Se verifica si el estatus del consumo es exitoso
                            validar(nickEncriptado, passEncriptado)
                        }
                    });
                }
            });
        }
        /* Este useEffect se activa solo cuando se detecta un cambio en los datos del formulario */
    }, [envio])

    /* Funcion cuando el usuario va escribiendo en el formulario */
    const llenado = (event) => {
        /* Se crean variables para usuario y contraseña respectivamente */
        let user = document.getElementsByName("usuario");
        let pass = document.getElementsByName("pass");
        /* Si los input son mayores a 25 se deja de escribir en los input user y pass*/
        if (user[0].value.length >= 25) {
            user[0].value = user[0].value.slice(0, 25);
        }
        if (pass[0].value.length >= 5) {
            pass[0].value = pass[0].value.slice(0, 25);
        }
        /* Se leen caracteres prohidos en los input */
        /* Se leen los caracteres ingresados en user y se validan para que no se escriban los caracteres invalidos */
        user[0].value = user[0].value.replace(/[-{ºª¢¬·¨çÇ|<(?#’”"¿)>*=&^}]/g, '').replace("[", '').replace("]", '').replace(' ', '').replace("“", '').replace("”", '')
        /* Se leen los caracteres ingresados en pass y se validan para que no se escriban los caracteres invalidos */
        pass[0].value = pass[0].value.replace(/[-{ºª¢¬·¨çÇ|<(?#’”"¿)>*=&^}]/g, '').replace("[", '').replace("]", '').replace(' ', '').replace("“", '').replace("”", '')
        user[0].value = palabrasBaneadas(user[0].value)
        pass[0].value = palabrasBaneadas(pass[0].value)
        /* Se leen los datos al pegar en el input user y valida que no se peguen caracteres invalidos */
        user[0].onpaste = user[0].value.replace(/[-{ºª¢¬·¨çÇ|<(?#’”"¿)>*=&^}]/g, '').replace("[", '').replace("]", '').replace(' ', '').replace("“", '').replace("”", '')
        /* Se leen los datos al pegar en el input pass y valida que no se peguen caracteres invalidos */
        pass[0].onpaste = user[0].value.replace(/[-{ºª¢¬·¨çÇ|<(?#’”"¿)>*=&^}]/g, '').replace("[", '').replace("]", '').replace(' ', '').replace("“", '').replace("”", '')
        user[0].onpaste = palabrasBaneadas(user[0].value)
        pass[0].onpaste = palabrasBaneadas(pass[0].value)
        /* Se llena el state datos para despues asignarlo en envio */
        setDatos({
            ...datos,
            [event.target.name]: event.target.value
        });
    }

    const bannedWords = [
        "FROM",
        "from",
        "SELECT",
        "select",
        "WHERE",
        "where",
        "delete",
        "DELETE",
        "TABLE",
        "table",
        "COLUMN",
        "column",
        "ROW",
        "row",
        "USER",
        "user",
        "ID",
        "SCRIPT",
        "script",
        "alert",
        "ALERT",
        "inner",
        "INNER",
        "HTML",
        "html",
        "query",
    ]

    let palabrasBaneadas = (value) => {
        for (let i = 0; i < bannedWords.length; i++) {
            let element = bannedWords[i];
            if (value.match(element)) {
                value = value.replace(element, "");
            }
        }
        return value
    }



    /* Valida Permanencia en Login cuando se regresa desde Principal, Esto solo ha funcionado en Mozilla */
    // window.addEventListener("load", ()=>{
    //     setGif(false);
    //     if(parseInt(sessionStorage.getItem("valido"))==1){
    //         sessionStorage.setItem("valido", 0);
    //     }
    // });

    /* Return del componente, aqui se regresa el formulario de login, imagenes, etc. */
    return (
        /* Contenedor principal */
        <div className="principal">
            {
                /* Se muestra el componente Loading */
                gif ? <Loading /> : null
            }
            {/* Se muestran los componentes ModalCorrecto y ModalError */}
            {
                <ModalCorrecto mostrar={modalCorrecto} titulo={titulo} setMostrar={setModalCorrecto} validarLogin={validarLogin}></ModalCorrecto>
            }
            {
                <ModalGeneral mostrar={modalError} titulo={titulo} descripcion={descripcion} advertencia={advertencia} setMostrar={setModalError}></ModalGeneral>
            }
            {/* Contenedor de imagen y formulario */}
            <div className="contenedor">
                {/* Contenedor de imagen y formulario */}
                <div className="fondo">
                    {/* Imagen Giza */}
                    <img className="logo" src={logo} />
                    {/* contenedor del Login */}
                    <div className="card loginForm" >
                        {/* Contenedor de formulario*/}
                        <div className="card-body">
                            {/* Titulo del Login */}
                            <h1 style={{ color: "#3b4484", textAlign: "Center" }}>Iniciar Sesión</h1>
                            {/* Creacion del formulario, tendra evento onSubmint y onChange*/}
                            <form onSubmit={validarDatos} onChange={(e) => llenado(e)}>
                                {/* Contenedor de label e input de Usuario */}
                                <div className="form-group">
                                    <label htmlFor="txtNick" className="form-label">Usuario:</label>
                                    <input type="text" className="form-control" autoComplete="off" id="txtNick" name="usuario" />
                                </div>
                                {/* Contenedor de label e input de Contraseña */}
                                <div className="form-group">
                                    <label htmlFor="txtPass" className="form-label">Contraseña:</label>
                                    <input type="password" className="form-control" id="txtPass" name="pass" />
                                    <button type="button" onClick={click} id="icoPass">{type === "Oculto" ? eyeOculto : eye}</button>
                                </div>
                                {/* Boton de Iniciar Sesion*/}
                                <div className="botones">
                                    <button className="btnEntrar" id={type} type="submit">Entrar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* Footer con datos de Ayuda */}
            <footer className="footer">
                <h5>Ayuda y soporte técnico: 442-223-31-60</h5>
                <h5>Todos los derechos reservados Grupo Giza</h5>
            </footer>
        </div>
    )
}
/* Se exporta el componente Login, esto lo recibe el index.js del componente */
export default Login