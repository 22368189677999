/* Se import Modal de la libreria react-modal */
import Modal from 'react-modal';
/* Se import la imagen Correcto */
import CorrectoImg from'./img/correcto.png';
/* Se import la imagen Error */
import ErrorImg from './img/error.png';
/* Se import la imagen Warning */
import advertenciaImg from './img/warning.png';

/* Se configura para que Modal se muestre en contenedor Root de React */
Modal.setAppElement('#root')

/* Se crea funcion del Componente, en este caso tendra este componente props */
const ModalGeneral = (props) =>
{
    /* Return del Componente ModalCorrecto */
    return(
        /* Aqui se configura el Modal, desde el fondo donde se mostrara el modal, como el diseño del modal */
        <Modal className="modalAdvertencias" isOpen={props.mostrar} style={{
            /* Estilos del fondo del modal, en este caso, estara enmedio y tendra un fondo negro, opaco */
            overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
            /* Estilos del diseño del modal */
            content: {
                position: 'absolute',
                top: 'auto',
                left: 'auto',
                right: 'auto',
                bottom: 'auto',
                border: '1px solid #ccc',
                background: '#fff',
                overflow: 'auto',
                WebkitOverflowScrolling: 'touch',
                borderRadius: '10px',
                outline: 'none',
                padding: '20px',
                width: 'auto',
                height: 'auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: 'center',
                alignItems: 'center'
            }
        }}>
            {/* Cuerpo del modal, aqui se muestra el contenido del modal */}
            {
                <div>
                    {/* Validamos que si la prop de Advertencia es igual a Error, entonces se ponga la imagen y el boton Rojo*/}
                    {
                    props.advertencia==="Error" ? <div>
                        <img style={{width: "100px", height: "100px", textAlign:"center"}} src={ErrorImg}></img>
                        <h1>{props.titulo}</h1>
                        <p>{props.descripcion}</p>
                        <button className="btn btn-danger" autoFocus onClick={()=>{props.setMostrar(false)}}>Aceptar</button>
                    </div>
                    /* Validamos que si la prop de Advertencia es igual a Warning, entonces se ponga la imagen y el boton Amarrillo*/
                    :props.advertencia==="Warning"?<div>
                        <img style={{width: "100px", height: "100px", textAlign:"center"}} src={advertenciaImg}></img>
                        <h1>{props.titulo}</h1>
                        <p>{props.descripcion}</p>
                        <button className="btn btn-warning" autoFocus onClick={()=>{props.setMostrar(false)}}>Aceptar</button>
                    </div>
                    /* Validamos que si la prop de Advertencia es igual a Otra cosa, en este caso Correcto,
                    entonces se ponga la imagen y el boton Verde*/
                    :<div>
                        <img style={{width: "100px", height: "100px", textAlign:"center"}} src={CorrectoImg}></img>
                        <h1>{props.titulo}</h1>
                        <p>{props.descripcion}</p>
                        <button className="btn btn-success" autoFocus onClick={()=>{props.setMostrar(false)}}>Aceptar</button>
                    </div>
                        }
                    </div>
                }
        </Modal>
    )
}
/* Exportacion del componente, esto lo recibe el index.js del componente */
export default ModalGeneral
/* Si necesitas mas informacion, puedes leer la documentacion
http://reactcommunity.org/react-modal/
*/