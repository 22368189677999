/* Importamos React*/
import React, { useEffect, useState } from 'react';
/* Importamos scss de App*/
import './App.css';
/* Importamos componente de Login*/
import Login from './Components/Login';
/* Importamos componente de Principal*/
import Principal from './Components/Principal';
/* Importamos DataProvider del context*/
import { DataProvider } from "./Components/dataContext/dataContext"
/* Funcion principal de la App React, aqui ira toda la logica principal */
function App() {
    /* Accedemos a los datos del context */
    const [validar, setValidar] = useState(false);
    /* Accedemos a los datos del context */
    const [permanencia, setPermanencia] = useState(sessionStorage.getItem("valido"));
    /* Return de App*/
    function validarLogin(param, permanencia) {
        setValidar(param)
        setPermanencia(permanencia)
    }
    useEffect(()=>{
        sessionStorage.getItem("valido");
    },[sessionStorage.getItem("valido")])
    return (
        // DataProvider, encapsula el context en todos los componentes
        <DataProvider>
            {/* Mapa de Rutas, ademas forzamos a que recargue la pagina */}
            {validar ? <Principal validarLogin = {validarLogin} /> :
            permanencia === "1" ?<Principal validarLogin = {validarLogin}/>:<Login validarLogin = {validarLogin}/>}
        </DataProvider>
    )
}

/* Se exporta el componente App, esto lo recibe el index.js del componente */
export default App;