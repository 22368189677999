/* Este contexto contiene los datos de id, tipo y permanencia
que se utiliza despues en Principal  */

/* Importamos useState y ceateContext*/
import React, { useState, createContext } from 'react';
/* Exportamos el dataContext, el cual funciona para obtener y modificar los datos del context */
export const DataContext = createContext();
/* Exportamos el DataProvider, el cual contiene todos los estados */
export const DataProvider = ({ children }) => {
    /* State de tipo */
    const [tipo, setTipo] = useState("")
    /* State del Id */
    const [id, setId] = useState("")
    /* State de Permanencia */
    const [permanencia, setPermanencia] = useState("")
    /* Arreglo con los datos del context */
    const datos = {
        tipo,
        setTipo,
        id,
        setId,
        permanencia,
        setPermanencia
    }
    /* Return del context el cual contiene el provider del context y sus valores */
    /* children es para que los demas componentes puedan acceder al context */
    return (
        <DataContext.Provider value={datos}>
            
            {children}
        </DataContext.Provider>
    )
}