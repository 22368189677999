/* Se import React */
import React from 'react';
/* Se import Link de react-router-dom, Link es como una ancla de HTML, pero de React */
import {Link} from 'react-router-dom';
/* Se import Modal de la libreria react-modal */
import Modal from 'react-modal';
/* Se import la imagen correcto */
import correctoImg from'./img/correcto.png';
// Se importa el componente Principal
import Principal from '../Principal';
/* Se configura para que Modal se muestre en contenedor Root de React */
Modal.setAppElement('#root')
/* Se valida que si esta ya en pagina principal y regresa, te regrese a Principal*/
// function validacion (){
//     if (parseInt(sessionStorage.getItem("valido"))===1){
//         window.location.href ="/principal";
//     }
// }
/* Se crea funcion del Componente, en este caso tendra este componente props */
const ModalCorrecto = (props) =>
{
    /* Return del Componente ModalCorrecto */
    return(
        /* Aqui se configura el Modal, desde el fondo donde se mostrara el modal, como el diseño del modal */
        <Modal className="modalCorrecto" isOpen={props.mostrar} style={{
            /* Estilos del fondo del modal, en este caso, estara enmedio y tendra un fondo negro, opaco */
            overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
            /* Estilos del diseño del modal */
            content: {
                position: 'sticky',
                top: 'auto',
                left: 'auto',
                right: 'auto',
                bottom: 'auto',
                border: '1px solid #ccc',
                background: '#fff',
                overflow: 'auto',
                WebkitOverflowScrolling: 'touch',
                borderRadius: '10px',
                outline: 'none',
                padding: '20px',
                width: 'auto',
                height: 'auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: 'center',
                alignItems: 'center',
                zIndex: 10
            }
        }}>
            {/* Cuerpo del modal, aqui se muestra el contenido del modal */}
            {
                <div>
                    {/* Imagen que tendra el modal */}
                    <img style={{width: "100px", height: "100px", textAlign:"center"}} src={correctoImg}></img>
                    {/* Titulo del modal*/}
                    <h1>{props.titulo}</h1>
                    {/* Link para redireccionar a principal*/}
                    <button className="btn btn-success" onClick={()=>{{props.setMostrar(false)}; props.validarLogin(true, "")}} autoFocus>Aceptar</button>
                    {/*<Link to = "/principal" onClick={validacion}><button className="btn btn-success"  autoFocus >Aceptar</button></Link> */}
                </div>
                }
        </Modal>
    )
}

/* Exportacion del componente, esto lo recibe el index.js del componente */
export default ModalCorrecto
/* Si necesitas mas informacion, puedes leer la documentacion
http://reactcommunity.org/react-modal/
*/