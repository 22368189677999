/* Importamos React, junto con useState, useEffect, useContext */
import React, { useState, useEffect, useContext } from 'react';
/* Se importa uri de login */
import { registroLineas } from '../../utils/connections/uris';
/* Importamos el scss de Principal */
import './Principal.scss';
/* Importamos el componente Loading */
import Loading from '../Loading'
/* Importamos el componente ModalGeneral */
import ModalGeneral from '../ModalGeneral';
/* Importamos el logo de Giza */
import logo from '../../img/Giza_blanco_1.png';
/* Importamos el dataContext, en Principal se usa para obtener los datos del context */
import { DataContext } from '../dataContext/dataContext';

/* Creamos la funcion del componente */
const Principal = ({ validarLogin }) => {
    /* creamos el state de activar Gif */
    const [gif, setGif] = useState(false);
    /* creamos el state de envio, sirve para mandar los datos al API*/
    const [envio, setEnvio] = useState({});
    /* creamos el state de mostrar, sirve para mostrar Modal */
    const [mostrar, setMostrar] = useState(false);
    /* creamos el state de Advertencia, sirve para mostrar el icono del modal */
    const [advertencia, setAdvertencia] = useState("");
    /* creamos el state de Titulo, sirve para mostrar el titulo del modal */
    const [titulo, setTitulo] = useState("");
    /* creamos el state de descripcion, sirve para mostrar la descripcion del modal */
    const [descripcion, setDescripcion] = useState("");
    /* creamos el state de datos, sirve para guardar los datos del formulario, el cual contiene, el numero, el tipo de usuario y el id de usuario*/
    const [datos, setDatos] = useState({ numero: "", tipo: "", usuario_id: "" });
    /* Accedemos a los datos del context */
    const { tipo, id } = useContext(DataContext);

    /* Funcion donde validamos si los input no estan vacios */
    const validar = (event) => {
        /* Prevenimos accion por defecto, ya que recargara la pagina */
        event.preventDefault();
        /* Creamos variable con numero y confirmar numero*/
        var numero = document.getElementById("txtNumero");
        var confirmarNumero = document.getElementById("txtconfNumero");
        /* Mostramos GIF */
        setGif(true);
        /* Validamos si el input Numero no esten vacios */
        if (numero.value.length === 0) {
            /* Paramos GIF */
            setGif(false);
            /* Mostramos Modal */
            setMostrar(true);
            /* Se modifica el state de Advertencia, esto en el modal, mostrara la imagen de Warning */
            setAdvertencia("Warning");
            /* Se modifica el state Titulo, que se muestra en el modal */
            setTitulo("Campo Número Vacío")
            /* Se modifica el state Descripcion, que se muestra en el modal */
            setDescripcion("Campo número vacío")
        }
        /* Validamos si el input Confirmar Numero no este vacio*/
        if (confirmarNumero.value.length === 0) {
            /* Paramos GIF */
            setGif(false);
            /* Mostramos Modal */
            setMostrar(true);
            /* Se modifica el state de Advertencia, esto en el modal, mostrara la imagen de Warning */
            setAdvertencia("Warning");
            /* Se modifica el state Titulo, que se muestra en el modal */
            setTitulo("Campo Confirmar Número Vacío")
            /* Se modifica el state Descripcion, que se muestra en el modal */
            setDescripcion("Campo confirmar número vacío")
        }

        /* Si Numero y Confirmar Numero es diferente a 0, que se hagan otras validaciones */
        if (numero.value.length !== 0 && confirmarNumero.value.length !== 0) {
            /* Si el Numero o Confirmar numero tienen menos de 10 digitos en el input, mostrara modal */
            if (numero.value.length < 10 || confirmarNumero.value.length < 10) {
                /* Se detienen Gif */
                setGif(false);
                /* Se muestra el Modal */
                setMostrar(true);
                /* Se asigna a state Advertencia, en el modal, mostrara imagen Warning */
                setAdvertencia("Warning");
                /* Se asigna state Titulo, y muestra Titulo en el Modal  */
                setTitulo("Verifique que el numero sea de 10 Dígitos")
                /* Se asigna state Descripcion, y muestra la descricion en el modal */
                setDescripcion("Los números no cuentan con el formato correcto, verifique que lo halla ingresado correctamente")
            } else {
                /* Se valida que los numeros no coinciden */
                if (numero.value !== confirmarNumero.value) {
                    /* Se detienen Gif */
                    setGif(false);
                    /* Se muestra el Modal */
                    setMostrar(true);
                    /* Se asigna a state Advertencia, en el modal, mostrara imagen Warning */
                    setAdvertencia("Warning");
                    /* Se asigna state Titulo, y muestra Titulo en el Modal  */
                    setTitulo("Los Números no Coinciden")
                    /* Se asigna state Descripcion, y muestra la descricion en el modal */
                    setDescripcion("Los números no coinciden, verifique que concuerden")
                } else {
                    /* Se modifica el state Envio, con los datos, estos datos se envian al API */
                    setEnvio(datos)
                    /* Se modifica state mostrar, que cierra el modal */
                    setMostrar(false)
                }
            }
        }
    }

    /* Funcion para validar que los campos Numero y Confirmar Numero */
    const llenado = (event) => {
        /* Creamos variable de Numero y Confirmar Numero */
        var numero = document.getElementsByName("numero");
        var confNumero = document.getElementsByName("confNumero");
        /* Validamos que despues de 10 digitos, se deje de escribir en Numero */
        if (numero[0].value.length >= 10) {
            numero[0].value = numero[0].value.slice(0, 10);
        }
        /* Validamos que despues de 10 digitos, se deje de escribir en Confirmar Numero */
        if (confNumero[0].value.length >= 10) {
            confNumero[0].value = confNumero[0].value.slice(0, 10);
        }
        /* Validamos que solo sean numeros
        Validamos que sean solo numeros en Campo Numero*/
        numero[0].value = numero[0].value.replace(/[-{ºª¢¬·¨çÇ|<(?#’”"¿)>^a-zA-Z!$%&/=`+´.,;:_@∞÷≠‚€œæ®†¥øπå∫∂~§¶™ƒΩ∑©√–…„µß≤ñÑ*}]/g, '').replace("[", '').replace("]", '').replace(' ', '').replace("“", '').replace("”", '').replace("\\", "")
        /* Validamos que sean solo numeros en Campo Confirmar Numero */
        confNumero[0].value = confNumero[0].value.replace(/[-{ºª¢¬·¨çÇ|<(?#’”"¿)>^a-zA-Z!$%&/=`+´.,;:_@∞÷≠‚€œæ®†¥øπå∫∂~§¶™ƒΩ∑©√–…„µß≤ñÑ*}]/g, '').replace("[", '').replace("]", '').replace(' ', '').replace("“", '').replace("”", '').replace("\\", "")
        /* Validamos que sean solo numeros en Campo Numero al Pegar */
        numero[0].onpaste = numero[0].value.replace(/[-{ºª¢¬·¨çÇ|<(?#’”"¿)>^a-zA-Z!$%&/=`+´.,;:_@∞÷≠‚€œæ®†¥øπå∫∂~§¶™ƒΩ∑©√–…„µß≤ñÑ*}]/g, '').replace("[", '').replace("]", '').replace(' ', '').replace("“", '').replace("”", '').replace("\\", "")
        /* Validamos que sean solo numeros en Campo Confirmar Numero al Pegar*/
        confNumero[0].onpaste = confNumero[0].value.replace(/[-{ºª¢¬·¨çÇ|<(?#’”"¿)>^a-zA-Z!$%&/=`+´.,;:_@∞÷≠‚€œæ®†¥øπå∫∂~§¶™ƒΩ∑©√–…„µß≤ñÑ*}]/g, '').replace("[", '').replace("]", '').replace(' ', '').replace("“", '').replace("”", '').replace("\\", "")
        /* Se llena el state de Datos, con los datos que se enviaran al API */
        setDatos({
            ...datos,
            [event.target.name]: event.target.value,
            tipo: tipo,
            usuario_id: id
        });
    }
    /* useEffect para consumir API de Activaciones */
    useEffect(() => {
        /* Creamos funcion para validar lo que se regresa la API */
        const validar = () => { 
            /* Fetch para consumir API */
            fetch(registroLineas, {
                /* Metodo POST */
                method: 'POST',
                /* Modo Cors, Este Modo cambia las cabeceras de peticion... para ver mas accede a https://developer.mozilla.org/es/docs/Web/HTTP/CORS*/
                /* Se envia en Json los datos de la peticion */
                body: JSON.stringify(envio),
                /* Se agrega al Header de la peticion que el contenido sera tipo Json*/
                headers: {
                    "Content-Type": "application/json"
                }
                /* La respuesta la convierte a Json */
            }).then(data => data.json())
                /* Se leen los datos de la respuesta */
                .then(response_Admin => {
                    /* Se muestra el gif, mientras se hace la peticion*/
                    setGif(true);
                    if (response_Admin.Estado === 0) {
                        /* Detiene el gif de loading */
                        setGif(false);
                        /* Se modifica el state de advertencia, para mostrar la imagen en Modal */
                        setAdvertencia("Warning");
                        /* Se modifica el state de Titulo, para mostrarse en el Modal */
                        setTitulo(response_Admin.Titulo)
                        /* Se modifica el state de descripcion, para mostrarse en el Modal */
                        setDescripcion(response_Admin.Descripcion)
                        /* Se muestra el Modal */
                        setMostrar(true);
                    }
                    /* Esta API revisa si los numeros, ya fueron registrados y si ya estan registrados, muestra un mensaje
                    si no fueron registrados, se le hara una recarga para activarlo y lo mostrara al usuario,
                    tambien si llega al limite de numeros activados mostrara un mensaje, que temporalmente no puede hacer activaciones
                    Si la respuesta es igual a 1, entonces el numero no esta activado y regresa Modal Correcto*/
                    if (response_Admin.Estado === 1) {
                        /* Se detiene el GIF */
                        setGif(false);
                        /* Se modifica el state Advertencia, esto en el modal muestra la imagen */
                        setAdvertencia("Correcto");
                        /* Se modifica el state Titulo, esto lo muestra en el modal */
                        setTitulo(response_Admin.Titulo)
                        /* Se modifica el state de Descripcion, esto lo muestra en el modal*/
                        setDescripcion(response_Admin.Descripcion)
                        /* Se muestra el modal */
                        setMostrar(true);
                    }
                    /* Se detiene Gif */
                    setGif(false)
                }).catch(function (error) {
                    /* Se detiene el Gif de carga */
                    setGif(false);
                    /* Se modifica el state de la imagen del Modal */
                    setAdvertencia("Error");
                    /* Se modifica el state del titulo del Modal */
                    setTitulo("Hubo un problema con la petición")
                    /* Se modifica el state de la descripcion del Modal */
                    setDescripcion('Revise por favor su conexión a internet')
                    /* Se modifica el state de Mostrar, esto muestra el modal de Advertencias y Errores */
                    setMostrar(true);
                    /* Se limpia el state Envio */
                    setEnvio({});
                    /* Se asigna 0 a sessionStorage */
                    sessionStorage.setItem('valido', 0);
                })
            /* Se Vacian los campos Numero y Confirmar Numero */
            document.getElementById("txtNumero").value = '';
            document.getElementById("txtconfNumero").value = '';
        };
        /* Se valida que si state Envio esta vacio, no hace la peticion a la API */
        if (Object.keys(envio).length !== 0) {
            validar();
        }
        /* Se hace el useEffect solo cuando cambia state envio */
    }, [envio])
    /* Funcion cerrar Sesion, remuevele la variable de sesion */
    const cerrarSesion = () => {
        sessionStorage.removeItem("valido");
        validarLogin(false, "");
    }
    useEffect(() => {
        validarLogin(true, sessionStorage.getItem("valido"));
    }, [sessionStorage.getItem("valido") === 1])

    /* Return del componente Principal */
    return (

        /* Contenedor Principal */
        <div className="main-content">
            {/* Llamada al componente Loading */}
            {
                gif ? <Loading /> : null
            }

            {/* Contenedor de imagen fondo */}
            <div className="contenedorActivaciones">
                {/* Contenedor del form y logo Giza */}
                <div className="fondoActivaciones">
                    {/* Logo Giza */}
                    <img className="logo" src={logo} />
                    {/*contenedor Form Activaciones */}
                    <div className="card loginFormActivaciones">
                        {/* Form Activaciones */}
                        <div className="card-body activacionesForm">
                            {/* Titulo del Form */}
                            <h1 style={{ color: "#3b4484", textAlign: "Center" }}>Activaciones</h1>
                            {/* Form Activaciones, con evento, onSubmit y onChange */}
                            <form onSubmit={validar} onChange={llenado}>
                                {/* Label e Input de Numero */}
                                <div className="form-group">
                                    <label htmlFor="txtNick" className="form-label">Número:</label>
                                    <input type="tel" className="form-control" autoComplete="off" id="txtNumero" name="numero" />
                                </div>
                                {/* Label e Input de Confirmar Numero */}
                                <div className="form-group">
                                    <label htmlFor="txtPass" className="form-label">Confirmar Número:</label>
                                    <input type="tel" className="form-control" autoComplete="off" id="txtconfNumero" name="confNumero" />
                                </div>
                                {/* Boton Recargar y Cerrar Sesion */}
                                <div className="botones">
                                    <button className="btnRecargar" type="submit">Recargar</button>
                                    <button onClick={cerrarSesion} type="button" className="btnCerrar">Cerrar Sesión</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/* Footer, incluye informacion de soporte tecnico */}
                <footer className="footerPrincipal">
                    <h5>Ayuda y soporte técnico: 442-223-31-60</h5>
                    <h5>Todos los derechos reservados Grupo Giza</h5>
                </footer>
            </div>
            {/* Llamamos ModalGeneral */}
            <ModalGeneral mostrar={mostrar} titulo={titulo} descripcion={descripcion} advertencia={advertencia} setMostrar={setMostrar}></ModalGeneral>
        </div>
    )
}

/* Se exporta el componente Principal, esto lo recibe el index.js del componente */
export default Principal