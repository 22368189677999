const Contants = require("./constants.js");

const { CONEXION_BASE, PORT, PRODUCT_NAME } = Contants;

const loginGeneral = "/usuario/login"
const registrarLineas = "/recarga/recargarNumero";
const encript = "/encriptar/encriptarDesencriptar"

export const login = CONEXION_BASE + PORT + PRODUCT_NAME + loginGeneral;
export const registroLineas = CONEXION_BASE + PORT + PRODUCT_NAME + registrarLineas;
export const encriptarDesencriptar = CONEXION_BASE + PORT + PRODUCT_NAME + encript;
/**
 * LOGS
 */